import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

const withFormControl = (Component: FC<Record<string, any>>) => {
  const Wrapper = React.forwardRef((props: any, ref: (arg0: HTMLElement) => HTMLElement) =>
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <Component
          onChange={(v: any[], instance?: any) => {
            field.onChange(v);
            props.handleOnChange && props.handleOnChange(v, instance);
          }}
          value={field.value}
          ref={(e: HTMLElement) => {
            ref(e);
            return field.ref;}
          }
          {...props}
        />
      )}
    />);

  Wrapper.displayName = Component.displayName || 'withFormControlWrapper';

  return Wrapper;
};

export default withFormControl;
