import axios from 'axios';
import i18n from 'i18next';

import { alert } from '@Utils/';
import { pendingRequestCount } from './apisUtils';

axios.defaults.baseURL = `/${i18n.language}`;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name="csrf-token"]')?.content;

pendingRequestCount('isInitial');

axios.interceptors.request.use(
  req => {
    pendingRequestCount('isRequest');

    return req;
  }
);

axios.interceptors.response.use(
  (res = { data: {} }) => {
    res.data.message && alert(res.data.message, 'success');
    pendingRequestCount('isResponse');

    return res;
  },
  (err = { response: { data: {} } }) => {
    err.response.data.message && alert(err.response.data.message, 'danger');
    pendingRequestCount('isResponse');

    return Promise.reject(err);
  }
);

export * from 'axios';

export default axios;
