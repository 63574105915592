import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

export default function Session(signOutForm) {
  this.signOutForm = signOutForm;
  this.zendeskLogoutUrl = signOutForm.getAttribute('data-zendesk-logout-url');
  this.authenticityToken = signOutForm['authenticity_token'] && signOutForm['authenticity_token'].value;
  this.heartBeatInterval = signOutForm.getAttribute('data-heart-beat-interval');
  this.defaultAjaxSettings = { headers: { 'X-CSRF-Token': this.authenticityToken }, baseURL: this.signOutForm.getAttribute('action') };
}

Session.prototype = {
  keepAlive: function () {
    const ajaxSettings = Object.assign({}, {
      method: 'PATCH',
      data: { state: 'keep_alive' },
    }, this.defaultAjaxSettings);
    axios(ajaxSettings).catch(() => window.location.reload());
    return this;
  },
  signOut: function () {
    const self = this;
    if (!self.zendeskLogoutUrl) return self.signOutForm.submit();
    axios({ url: self.zendeskLogoutUrl, adapter: jsonpAdapter, callbackParamName: 'callback' })
      .then(() => {}).catch(() => {}).then(() => {self.signOutForm.submit();});
  },
  startHeartBeat: function () {
    this.heartBeatInterval && new HeartBeat(this.heartBeatInterval, this.defaultAjaxSettings).start();
  }
};

export function HeartBeat (heartBeatInterval, defaultAjaxSettings) {
  this.heartBeatInterval = heartBeatInterval;
  this.ajaxSettings = Object.assign({}, {
    method: 'PATCH',
    data: { state: 'heart_beat' }
  }, defaultAjaxSettings);
}

HeartBeat.prototype = {
  start: function () {this.callAjax();},
  beat: function () {setTimeout(() => this.callAjax(), this.heartBeatInterval * 1000);},
  reload: function () {window.location.reload();},
  callAjax: function () {
    const self = this;
    axios(self.ajaxSettings).then(() => self.beat()).catch(() => self.reload());
  }
};
