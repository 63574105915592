import { useEffect, useRef, ChangeEvent, useState, MouseEvent } from 'react';

import { FileUploadProps } from 'bundles/components/common/Form/FileUpload/types';
import { setDataTransfer } from './utils';

const useFileUpload = ({ onChange }: Pick<FileUploadProps, 'onChange'>) => {
  const refInput = useRef<HTMLInputElement | null>(null);
  const [dragEnter, setDragEnter] = useState('');
  const [fileAnimation, setFileAnimation] = useState(false);

  const handleChange = (e: ChangeEvent <HTMLInputElement>) => {
    setFileAnimation(true);
    setDragEnter('');
    onChange(e.target.files);
  };

  const handleClear = (index: number) => {
    const dt = setDataTransfer(refInput, index);

    (refInput.current as HTMLInputElement).files = dt.files;

    onChange(dt.files);
  };

  const handleDragEnter = (e: MouseEvent<HTMLDivElement>) => e.target !== refInput.current && setDragEnter('drag-enter');

  const handleDragLeave = (e: MouseEvent<HTMLDivElement>) => e.target === refInput.current && setDragEnter('');

  const handleOnDrop = () => setDragEnter('');

  const handleFileAnimation = () => setFileAnimation(false);

  const handleBeforUnload = () => (refInput.current as HTMLInputElement).files = new DataTransfer().files;

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforUnload);

    return () => window.removeEventListener('beforeunload', handleBeforUnload);
  }, []); //reset input on page out

  return { handleChange, handleClear, handleDragEnter, handleDragLeave, handleOnDrop, handleFileAnimation, dragEnter, fileAnimation, refInput };
};

export default useFileUpload;
