import React, { useState, useLayoutEffect, FC } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';
import { THeaderProps } from 'bundles/types';
import { setTheme } from './utils';
import useDebounce from 'bundles/hooks/useDebounce';
import Language  from 'bundles/components/common/Language';
import { withSession } from 'bundles/hocs';

import './styles.scss';

const PublicHeader: FC<THeaderProps> = ({ languages, current_language, user_signed_in, user_type_param, handleSignOut }) => {
  const { t, i18n } = useTranslation();
  const [scrollY, setScrollY] = useState(0);
  const [scrolledBackTop, setScrolledBackTop] = useState(false);

  const { debounceRequest } = useDebounce(()=> setScrollY(() => {
    setScrolledBackTop(!window.scrollY);
    return window.scrollY;
  }), 300);

  useLayoutEffect(() => {
    window.addEventListener('scroll', debounceRequest);

    return () => window.removeEventListener('scroll', debounceRequest);
  }, []);

  return (
    <header>
      <Navbar
        bg={setTheme}
        expand="lg"
        variant={setTheme === 'dark' ? setTheme : 'light'}
        fixed="top"
        className={`navbar-top ${scrollY ? 'navbar-top-on-scrolling' : ''} ${scrolledBackTop ? 'navbar-top-on-back-top' : ''}`.trim()}
      >
        <Container>
          <Navbar.Brand href={`/${i18n.language}${URL.HOME_PAGE}`}>
            <img alt="ezeeWallet" src={setTheme === 'dark' ? publicImages.logoWhite : publicImages.logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="navigation-button p-0">
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end">
            <Nav className="align-items-center">
              <If condition={(user_type_param === 'home' || user_type_param === 'pages' || user_type_param === 'fees_and_limits') && !user_signed_in}>
                <Nav.Link href={`/${i18n.language}${URL.BUSINESS}`}>{t('public.labels.business')}</Nav.Link>
                <Nav.Link href={`/${i18n.language}${URL.PERSONAL}`}>{t('public.labels.personal')}</Nav.Link>
                <Nav.Link href={`/${i18n.language}${URL.ABOUT_US}`}>{t('public.labels.about_us')}</Nav.Link>
                <Nav.Link href={`/${i18n.language}${URL.THE_VIP_CLUB}`}>{t('public.labels.the_vip_club')}</Nav.Link>
              </If>

              <If condition={user_signed_in}>
                <Nav.Link href={`/${i18n.language}/${user_type_param}/dashboard`}>{t('titles.dashboard')}</Nav.Link>
              </If>

              <If condition={user_type_param !== 'admins'}>
                <Language languages={languages} current_language={current_language} />
              </If>

              <If condition={!(user_type_param === 'sessions' || (user_type_param === 'admins' && !user_signed_in))}>
                <Nav.Link href={`/${i18n.language}${URL.SESSION_NEW}`} className="btn btn-default p-4 text-dark" onClick={handleSignOut}>
                  {t(user_signed_in ? 'labels.sign_out' : 'public.labels.sign_in')}
                </Nav.Link>
              </If>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

const PublicHeaderWithSession =  withSession(PublicHeader);

const PublicHeaderWithSessionWrapper: FC<THeaderProps> = props => <PublicHeaderWithSession {...props} />;

export default PublicHeaderWithSessionWrapper;
