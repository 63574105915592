import { i18n } from '@Utils/';
import isMatch from 'date-fns/isMatch';
import format from 'date-fns/format';

import parseFormattedAmount from 'bundles/utils/parseFormattedAmount';
import { capitalize } from 'bundles/utils';

export const setIdentifierPerCountry = (value, country) => {
  const countryLowerCase = country.toLowerCase();

  switch (countryLowerCase) {
  case 'ar':
    return /^\d{7,9}|\d{11}$/.test(value);
  case 'br':
    return /^\d{11,14}$/.test(value);
  case 'co':
    return /^\d{6,10}$/.test(value);
  case 'cl':
    return /^\d{8,9}$/.test(value);
  case 'in':
    return /^[A-Z]{5}\d{4}[A-Z0-9]{1}$/.test(value);
  case 'mx':
    return /^\d{10,18}$/.test(value);
  case 'pe':
    return /^\d{8,9}$/.test(value);
  default:
    return true;
  }
};

export const validateUnitedStatesSortCode = value => {
  if (value.length !== 9 || Number(parseFloat(value)) === value || value[0] === '5') return i18n.t('messages.errors.forms.invalid_sort_code');

  const checksumTotal = (7 * (parseInt(value.charAt(0),10) + parseInt(value.charAt(3),10) + parseInt(value.charAt(6),10))) +
        (3 * (parseInt(value.charAt(1),10) + parseInt(value.charAt(4),10) + parseInt(value.charAt(7),10))) +
        (9 * (parseInt(value.charAt(2),10) + parseInt(value.charAt(5),10) + parseInt(value.charAt(8),10)));

  const checksumMod = checksumTotal % 10;
  if (checksumMod !== 0) return i18n.t('messages.errors.forms.invalid_sort_code');
};

export const setMultiselectValuesFor = (selectedValues, AllValues = []) => selectedValues ? AllValues.filter((e) => selectedValues.includes(String(e.id))) : undefined;

export const testFileListWithRegExp = (fileList, regexp) => {
  let flag = true;

  [...fileList].map((file) => { if(!regexp.test(file?.name)) flag = false; });

  return flag;
};

export const testFileListForSize = (fileList, size) => {
  let flag = true;

  [...fileList].map((file) => { if(file?.size > size) flag = false; });

  return flag;
};

export const testValidDate = (value) => {
  const datePattern = 'yyyy-MM-dd';
  const valueFormatted  = format(new Date(value), datePattern);

  return isMatch(valueFormatted, 'yyyy-MM-dd');
};

export const testSortCode = (value, country, canadaSortCodeRegExp) => {
  switch(country) {
  case 'Canada':
    return canadaSortCodeRegExp.test(value) ? undefined : i18n.t('messages.errors.forms.invalid_sort_code');
  case 'United States':
    return validateUnitedStatesSortCode(value);
  default:
    return undefined;
  }
};

export const testQueryByEmails = (value, emailRegExp) => {
  const emails = value.trim().split(/[\s,\n,;]+/);

  for (let i = 0; i < emails.length; i++) {
    if (!emailRegExp.test(emails[i])) {
      return i18n.t('messages.errors.forms.invalid_email_format_in_query');
    }
  }
};

export const checkMaxAmount = (value, details) => {
  if (typeof details.maximum_limit === 'bigint') {
    return BigInt(Math.trunc(value)) < details.maximum_limit;
  }

  return value <= parseFormattedAmount(details.maximum_limit, details);
};

export const setLabel = (label = '') => capitalize(label.split(/\]|\[/).filter(Boolean).pop()?.replace(/_/g, ' '));
