import { StepsState, StepsAction } from './types';
import { ANIMATION } from 'bundles/constants';

const StepsReducer = (state: StepsState, action: StepsAction) => {
  switch (action.type) {
  case ANIMATION.NEXT_STEP:
    return {
      ...state,
      currentStep: state.currentStep + 1,
      animation: true,
      animationDirection: action.payload.animationDirection || 'animation--direction-right'
    };
  case ANIMATION.BACK_STEP:
    return {
      ...state,
      currentStep: state.currentStep - 1,
      animation: true,
      animationDirection: action.payload.animationDirection || 'animation--direction-left'
    };
  case ANIMATION.COMPLETE_STEPS:
    return {
      ...state,
      currentStep: 1,
      animation: true,
      animationDirection: 'animation--direction-none'
    };
  case ANIMATION.ANIMATION:
    return { ...state, ...action.payload };
  default:
    return state;
  }
};

export default StepsReducer;
