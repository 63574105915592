import React, { forwardRef } from 'react';

import { processFieldName } from 'bundles/utils/index';
import { FormFieldInterface } from '../types';
import { InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const Radio = forwardRef(({ register, errors = {}, validate = {}, name = '', classNameGroup = '', label, ...rest }: FormFieldInterface, ref: InputRef) =>
  <div className={`custom-control custom-radio ${classNameGroup}`.trim()}>
    <input
      id={rest.id || name}
      type="radio"
      {...register!(name, { validate: validate })}
      className={`custom-control-input ${processFieldName(errors as any, name) ? 'form-error' : ''}`}
      {...rest}
      {...(ref && { ref: ref })}
    />
    <label htmlFor={rest.id} className={`custom-control-label ${validate.isRequired ? 'required' : ''}`.trim()}>
      {label}
    </label>
  </div>);

Radio.displayName = 'Radio';

export default Radio;



