import React, { createContext, ReactNode, useReducer } from 'react';

import MerchantRegistrationReducer from './MerchantRegistrationReducer';
import { MerchantRegistrationContext as MerchantRegistrationContextTypes, MerchantRegistrationState } from './types';

const initialState = { refreshReCaptcha: false } as MerchantRegistrationState;

export const MerchantRegistrationContext = createContext<MerchantRegistrationContextTypes>({
  state: initialState,
  dispatch: () => {}
});

export const MerchantRegistrationContextProvider = ({ children }: {children: ReactNode}) => {
  const [state, dispatch] = useReducer(MerchantRegistrationReducer, initialState);

  return (
    <MerchantRegistrationContext.Provider value={{ state, dispatch }}>
      {children}
    </MerchantRegistrationContext.Provider>
  );
};
