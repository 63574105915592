import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { locale } from './utils';
import { DatePickerInstance } from './types';
import { WithFormControlProps } from 'bundles/hocs/withFormControl/types';
import { dateFormat, i18n } from 'bundles/utils';
import subMonths from 'date-fns/subMonths';

registerLocale('locale', locale);

const DatePickerInput = forwardRef((props: any, ref) => <input type="text" {...props} ref={ref} />);

DatePickerInput.displayName = 'DatepickerInput';

const DatePickerComponent = forwardRef((props: WithFormControlProps, ref: any) => {
  const { placeholder = i18n.t('labels.date_format'), value, dateFormat: df = 'yyyy-MM-dd', monthsShown = 2, ...rest } = props;

  const handleOnKeyUp = (e: any) => {
    if(!e.target?.value || e.target?.value.length < 10) return;

    ((rest.maxDate && new Date(e.target?.value) > new Date()) || new Date(e.target?.value) < subMonths(new Date(), 84)) &&
    (e.target.value = dateFormat.toLocaleString(new Date(), { year: 'numeric', month: 'numeric', day: 'numeric' }, 'sv-SE') as string);
  };

  return (
    <DatePicker
      placeholderText={placeholder}
      locale="locale"
      dateFormat={df}
      autoComplete="off"
      monthsShown={monthsShown}
      onChange={(v: string, e: any) => rest.onChange(v, e)}
      selected={value ? new Date(value) : null}
      {...rest}
      ref={(instance: DatePickerInstance) => {
        (instance && ref(instance.input));
      }}
      customInput={<DatePickerInput onKeyUp={handleOnKeyUp} />}
    />
  );
});

DatePickerComponent.displayName = 'Datepicker';

export default DatePickerComponent;
