import React, { FC } from 'react';
import { ComponentInterface } from 'bundles/hocs/withFilterPaginationCsv/types';

const HeaderActions = ({ HeaderActionsContent }: Pick<ComponentInterface, 'HeaderActionsContent'>) =>
  <div className="d-flex align-items-center">
    {HeaderActionsContent && <HeaderActionsContent />}
  </div>;

const HeaderActionsWrapper: FC<Pick<ComponentInterface, 'HeaderActionsContent'>> = (props) => <HeaderActions {...props} />;

export default HeaderActionsWrapper;
