import React from 'react';

import If from 'bundles/components/common/If';
import { TPlaceHolder } from './types';

import './styles.scss';

const Group = ({ condition, children, className }: TPlaceHolder) =>
  <If condition={condition === undefined || condition}>
    <div className={`position-relative ${className}`}>
      {children}
    </div>
  </If>;

export default Group;
