function Timer(elem, style, unit, time = 5000) {
  const progressBar = elem.getElementsByClassName('progress-bar')[0];
  let from = 0;
  const to = elem.offsetWidth;
  let fulfill = 0;
  const step = 25;
  let isPaused = false;
  const extendedCloseAnmiationTime = 1000 + time;

  this.start = function() {
    if( !progressBar) return;

    const timer = window.setInterval(function() {
      if(!isPaused) {
        from = from + step;
        fulfill = fulfill + to/(time/step);
        progressBar.style[style] = fulfill+unit;

        if (from === time) elem.className += ' alert--close-animation';
        if(from >= extendedCloseAnmiationTime) {
          elem.remove();
          clearInterval(timer);
        }
      }
    }, step);

    progressBar.style[style] = fulfill+unit;
  };

  this.pause = function() {isPaused = true;};
  this.resume = function() {isPaused = false;};
}

const createElement = (type, className, innerText, children) => {
  const el = document.createElement(type);
  if(className) { el.className += className; }
  if(innerText) { addInnerText(innerText, el); }

  children && children.forEach(c => el.appendChild(c));

  return el.cloneNode(true);
};

const tryParseText = (text) => {
  try {
    const parser = new DOMParser();
    const parsedInnerText = parser.parseFromString(text, 'text/html');

    return parsedInnerText.body.childNodes;
  } catch (error) {
    return false;
  }
};

const addInnerText = (innerText, el) => {
  const parsedText = tryParseText(innerText);

  return parsedText ? [...parsedText].forEach(c => el.appendChild(c)) : el.innerText = innerText;
};

const setAlertStack = (message, type, time) => {
  const container = createElement('div', 'alerts-container');
  const progressBar = createElement('div', 'progress-bar');
  const closeBtn = createElement('div', 'close');
  const paragraph = createElement('p', null, message);

  const alert = createElement('div', `alert alert-${type} alert-over alert-dismissible`, null, [paragraph, progressBar, closeBtn]);

  if (!document.getElementsByClassName('alerts-container').length) document.body.appendChild(container);
  document.getElementsByClassName('alerts-container')[0].appendChild(alert);

  const timer = new Timer(alert, 'right','px', time);

  timer.start();

  alert.addEventListener('mouseenter', () => timer.pause());
  alert.addEventListener('mouseleave', () => timer.resume());

  alert.querySelector('.close').addEventListener('click', () => alert.remove());
};

export default setAlertStack;
