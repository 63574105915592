import React, { useRef, useEffect, FC, MouseEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';

import { setUrlParams } from 'bundles/utils';
import { getPageData } from './utils';
import If from 'bundles/components/common/If';
import { IFilterProps } from './types';
import { setMultiselectValuesFor } from 'bundles/components/common/Form/utils';

const Filter = ({ FilterContent, AddCriteriaContent, methods, state, dispatch }: IFilterProps) => {
  const { t } = useTranslation();
  const addCriteriaBtnEl = useRef(null);

  const handleClickAddCriteria = (e: MouseEvent) => {
    e.preventDefault();
    dispatch({ type: 'TOGGLE_SHOW_ADD_CRITERIA', payload: { showAddCriteria: !state.showAddCriteria } });
  };

  const onHandleSubmitFilter = (f: Record<string, any>) => {
    setUrlParams(f, true, ['All']);
    getPageData(state, dispatch, { tableRowAnimated: '' });
  };

  useEffect(() => {
    if(!Object.keys(state.query_params).length) return;

    for (const [key, value] of Object.entries(state.query_params)) {
      if(key === 'sub_types') {
        methods.setValue('query[sub_types][]', setMultiselectValuesFor(state.query_params.sub_types, state.filter_selects_options?.available_sub_types));
        continue;
      }

      if(key === 'statuses') {
        methods.setValue('query[statuses][]', setMultiselectValuesFor(state.query_params.statuses, state.filter_selects_options?.transaction_available_statuses));
        continue;
      }

      methods.setValue(`query[${key}]`, value);
    }
  }, [state.query_params]);

  return (
    <div className="filter-wrapper mb-3">
      <Form onSubmit={methods.handleSubmit(onHandleSubmitFilter)} className="w-100 position-relative js-no-disable-submit-form d-flex justify-content-between">
        <If condition={FilterContent}>
          <FontAwesomeIcon icon={faBarsFilter} className="mr-4 mt-3 text-muted" size="sm" />
        </If>

        <div className="d-flex flex-gap flex-wrap flex-grow-1 align-items-start">
          <If condition={FilterContent}>
            { FilterContent && React.createElement(FilterContent as FC) }
          </If>

          <If condition={state.showAddCriteria}>
            { AddCriteriaContent && React.createElement(AddCriteriaContent as FC) }
          </If>
        </div>

        <If condition={AddCriteriaContent}>
          <div className="flex-shrink-0">
            <a href="#" className="px-0 text-gray py-2 d-block ml-6" onClick={handleClickAddCriteria} ref={addCriteriaBtnEl}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" size="sm" />
              {t('links.additional_criteria')}
            </a>
          </div>
        </If>
      </Form>
    </div>
  );
};

export default React.memo(Filter, (prevProps, nextProps) => {
  return !(prevProps.state.showAddCriteria !== nextProps.state.showAddCriteria ||
           prevProps.state.query_params !== nextProps.state.query_params);
});
