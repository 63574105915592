import React from 'react';

import { InputRef } from 'bundles/hocs/withFormControl/types';
import { FormFieldInterface } from './types';

import './styles.scss';

const Input = React.forwardRef(({ register, ...rest }: FormFieldInterface, ref: InputRef) => <input {...rest} {...register} ref={ref} />);

Input.displayName = 'Input';

export default Input;
