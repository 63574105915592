import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { StepsContext } from 'bundles/contexts/StepsContext';
import { ANIMATION } from 'bundles/constants';
import { IBackProps } from './types';

import './styles.scss';

const Back = ({ onClickBack, className = '', children, variant = 'primary' }: IBackProps) => {
  const { dispatch } = useContext(StepsContext);

  const back = (payload: Record<string, any>) => dispatch({ type: ANIMATION.BACK_STEP, payload: payload });
  const handleOnClick = (payload = {}) => onClickBack ? onClickBack(back) : back(payload);

  return <Button variant={variant} className={`btn-back ${className}`.trim()} onClick={() => handleOnClick()}>{children}</Button>;
};

export default Back;
