import _Table from './Table';
import _Text from './Text';
import _Group from './Group';
import _Shape from './Shape';
import _Button from './Button';
import _Card from './Card';

export default {
  Table: _Table,
  Text: _Text,
  Group: _Group,
  Shape: _Shape,
  Button: _Button,
  Card: _Card
};
