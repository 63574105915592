import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import If from 'bundles/components/common/If';
import { IContextAwareToggle } from './types';

const ContextAwareToggle = ({ as, children, eventKey, className = '', classNameIcon = 'color-light fa-xl', callback, icons = [] }: IContextAwareToggle) => {
  const currentEventKey = useContext(AccordionContext);
  const CustomTag = `${as}` as keyof JSX.IntrinsicElements;

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <CustomTag className={className} aria-expanded={isCurrentEventKey} onClick={decoratedOnClick}>
      {children}
      <If condition={icons.length}>
        <FontAwesomeIcon className={classNameIcon} icon={isCurrentEventKey ? icons[0] : icons[1]} />
      </If>
    </CustomTag>
  );
};

export default ContextAwareToggle;
