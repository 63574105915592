const process = (formData: Record<string, any>, data: Record<string, any>, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      process(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
};

const buildFormData = (data: Record<string, any>): Record<string, any> => {
  const formData = new FormData();

  process(formData, data);

  return formData;
};

export default buildFormData;
