import React, { useContext } from 'react';

import { StepsContext } from 'bundles/contexts/StepsContext';

import './styles.scss';

const ProgressIndicator = ({ steps, variant = '' } : {steps: number; variant: string}) => {
  const { state } = useContext(StepsContext);

  const setVariant = (currentStep: number, i: number) => {
    if(state.currentStep === i + 1) return 'in-progress';
    if(state.currentStep > i + 1) return 'completed';

    return '';
  };

  return (
    <div className={`progress-indicator d-flex justify-content-between position-relative ${variant}`.trim()}>
      {new Array(steps).fill('step')?.map((step, i) => <div key={i} className={`progress-indicator-step ${setVariant(state.currentStep, i)}`.trim()}>{i + 1}</div>)}
    </div>
  );
};

export default ProgressIndicator;
