import React, { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useDocumentVisible } from 'bundles/hooks';
import { RecaptchaProps } from './types';
import { i18n } from 'bundles/utils';

const Recaptcha = ({ sitekey, register, setValue, refreshReCaptcha: refreshReCaptchaProp, action }: RecaptchaProps) => {
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const documentVisible = useDocumentVisible();
  const handleVerify = useCallback((token: string) => setValue('g-recaptcha-response', token), []);

  useEffect(() => {
    const interval = setInterval(() => setRefreshReCaptcha(ps => !ps), 120000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => { setRefreshReCaptcha(ps => !ps); }, [documentVisible]);

  useEffect(() => { refreshReCaptchaProp !== undefined && setRefreshReCaptcha(refreshReCaptchaProp as boolean); }, [refreshReCaptchaProp]);

  return (
    <GoogleReCaptchaProvider language={i18n.language} useRecaptchaNet={true} useEnterprise={true} reCaptchaKey={sitekey}>
      <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={refreshReCaptcha} action={action} />

      <input type="hidden" {...register('g-recaptcha-response')} />
    </GoogleReCaptchaProvider>
  );
};

export default Recaptcha;
