import React, { FC }  from 'react';
import { Card } from 'react-bootstrap';

import SetPasswordForm from 'bundles/components/public/setPassword/SetPasswordForm';
import { LoginViewProps } from 'bundles/types';
import If from 'bundles/components/common/If';
import { i18n } from 'bundles/utils';

const SetPasswordView: FC<{id: string} & LoginViewProps> = (props) =>
  <div className="sign-in">
    <If condition={props.inline_message}>
      <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
    </If>
    
    <Card>
      <Card.Header as={'h1'} className="border-0">{i18n.t('public.titles.set_password') as string}</Card.Header>
      <Card.Body><SetPasswordForm {...props} /></Card.Body>
    </Card>
  </div>;

export default SetPasswordView;
