import React from 'react';

import If from 'bundles/components/common/If';
import { TPlaceHolder } from './types';

import './styles.scss';

const Shape = ({ condition, type, className, size }: TPlaceHolder) =>
  <If condition={condition === undefined || condition}>
    <div className={`element-placeholder--row ${className}`}>
      <div className={`element-placeholder element-placeholder--${type}`} style={{ height: size }} />
    </div>
  </If>;

export default Shape;
