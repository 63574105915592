import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

const setDatesRange = (startDate, endDate, minDateFrom, maxDateTo) => {
  const daysRange = differenceInCalendarDays(endDate, startDate);
  const daysRangeStartDateCurrentDate = differenceInCalendarDays(new Date(), startDate);

  if (isBefore(startDate, minDateFrom)) {
    startDate = minDateFrom;
    endDate = addDays(startDate, 89);
    maxDateTo = addDays(startDate, 89);
  }

  if (isAfter(startDate, endDate)) {
    endDate = startDate;
    maxDateTo = daysRangeStartDateCurrentDate <= 90 ? new Date() : addDays(startDate, 89);
  }

  if (isAfter(startDate, new Date())) {
    startDate = new Date();
    endDate = new Date();
    maxDateTo = new Date();
  }

  if (isAfter(endDate, new Date())) {
    endDate = new Date();
    maxDateTo = new Date();
  }

  if (daysRange > 90 && isAfter(startDate, minDateFrom) && isBefore(startDate, new Date())) {
    endDate = addDays(startDate, 89);
    maxDateTo = addDays(startDate, 89);
  }

  if (daysRange < 90 && isAfter(startDate, minDateFrom) && isBefore(startDate, new Date())) {
    maxDateTo = addDays(startDate, 89);
  }

  if (daysRangeStartDateCurrentDate <= 90 && isAfter(startDate, minDateFrom) && isBefore(startDate, new Date())) {
    maxDateTo = new Date();
  }

  return { startDateProcessed: startDate, endDateProcessed: endDate, maxDateToProcessed: maxDateTo };
};

export default setDatesRange;
