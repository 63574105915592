import React, { FC } from 'react';
import { InputGroup } from 'react-bootstrap';

import If from 'bundles/components/common/If';

const withInputGroup = (Component: FC<Record<string, any>>) => {
  const Wrapper = React.forwardRef((props: any, ref: (arg0: HTMLElement) => HTMLElement) => {
    const { prependLabel, appendLabel, ...restProps } = props;

    return (
      <InputGroup>
        <If condition={prependLabel}>
          <InputGroup.Prepend>
            <InputGroup.Text className="bg-white">{prependLabel}</InputGroup.Text>
          </InputGroup.Prepend>
        </If>

        <Component {...restProps} ref={ref} />

        <If condition={appendLabel}>
          <InputGroup.Append>
            <InputGroup.Text className="bg-white">{appendLabel}</InputGroup.Text>
          </InputGroup.Append>
        </If>
      </InputGroup>
    );
  });

  Wrapper.displayName = Component.displayName || 'withInputGroupWrapper';

  return Wrapper;
};

export default withInputGroup;
