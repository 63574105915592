import { LimitInterface } from 'bundles/components/common/Withdrawals/types';

const parseFormattedAmount = (amount: number | string | undefined, details: Partial<LimitInterface>) => {
  if (amount === undefined) return 0;
  if (typeof amount === 'number' || amount === undefined) return amount;

  const _amount = amount.replace(new RegExp(details?.delimiter as any, 'g'), '');
  return parseFloat(_amount.replace(details?.separator as any, '.'));
};

export default parseFormattedAmount;
