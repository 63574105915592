import React, { ReactNode } from 'react';

import Step from 'bundles/components/common/Steps/Step';
import ProgressIndicator from 'bundles/components/common/Steps/ProgressIndicator';

import { StepsContextProvider } from 'bundles/contexts/StepsContext';

const Steps = ({ children } : {children: ReactNode}) => <StepsContextProvider>{children}</StepsContextProvider>;

Steps.Step = Step;
Steps.ProgressIndicator = ProgressIndicator;

export { Steps, Step };
