import { MerchantRegistrationState, MerchantRegistrationContextAction } from './types';

const GET_PAGE_DATA = 'GET_PAGE_DATA';
const ON_BAD_REQUEST = 'ON_BAD_REQUEST';
const ON_NEXT = 'ON_NEXT';
const ON_CHECK_CURRENCY = 'ON_CHECK_CURRENCY';
const ON_SELECT_COMPANY_LEGAL_STATUS = 'ON_SELECT_COMPANY_LEGAL_STATUS';

const FilterReducer = (state: MerchantRegistrationState, action: MerchantRegistrationContextAction) => {
  switch (action.type) {
  case GET_PAGE_DATA:
    return { ...state, ...action.payload };
  case ON_NEXT:
    return { ...state, ...action.payload };
  case ON_CHECK_CURRENCY:
    return { ...state, checkedCurrencies: action.payload.checkedCurrencies };
  case ON_SELECT_COMPANY_LEGAL_STATUS:
    return { ...state, currentCompanyLegalStatus: action.payload.currentCompanyLegalStatus };
  case ON_BAD_REQUEST:
    return { ...state, refreshReCaptcha: action.payload.refreshReCaptcha, errorFields: { ...action.payload.errorFields } };
  default:
    throw new Error('no such event');
  }
};

export default FilterReducer;
