import React from 'react';

import If from 'bundles/components/common/If';
import { TPlaceHolder } from './types';

import './styles.scss';

const Button = ({ condition, className = '', size }: TPlaceHolder) =>
  <If condition={condition === undefined || condition}>
    <div className={`element-placeholder--row ${className}`}>
      <div className={'element-placeholder element-placeholder--button'} data-size={size} />
    </div>
  </If>;

export default Button;
