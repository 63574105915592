import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSort } from '@fortawesome/pro-light-svg-icons';
import { faSortUp, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { SORT_ORDER } from 'bundles/constants/mysql';

export const iconsMapper: Record<string, IconProp> = {
  [SORT_ORDER.NONE]: faSort,
  [SORT_ORDER.ASC]: faSortUp,
  [SORT_ORDER.DESC]: faSortDown
};
