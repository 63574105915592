import React, { createContext, useReducer, ReactNode, FC } from 'react';

import StepsReducer from './StepsReducer';
import { StepsContext as StepsContextType, StepsState } from './types';

const initialState: StepsState = {
  currentStep: 1,
  animation: false,
  animationDirection: 'animation--direction-right'
};

export const StepsContext = createContext<StepsContextType>({ state: initialState, dispatch: () => {} });

export const StepsContextProvider: FC<{children: ReactNode}> = ({ children }: {children: ReactNode}) => {
  const [state, dispatch] = useReducer(StepsReducer, initialState);

  return (
    <StepsContext.Provider value={{ state, dispatch }}>
      {children}
    </StepsContext.Provider>
  );
};

