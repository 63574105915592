import React, { useState } from 'react';
import {  NavDropdown } from 'react-bootstrap';

import { Language as LanguageProp, Languages } from './types';
import { setLanguageUrl } from './utils';

const Language = ({ languages, current_language }: {languages: Languages; current_language: LanguageProp}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <NavDropdown
      title={current_language?.code?.toUpperCase()}
      id="language"
      show={showDropdown}
      className="pl-2 languages"
      onMouseOver={() => setShowDropdown(true)}
      onMouseOut={() => setShowDropdown(false)}
    >
      {languages?.map((language: LanguageProp) =>
        <NavDropdown.Item key={language.id} href={setLanguageUrl(language.code)} className="py-2">
          {language.code.toUpperCase()}
        </NavDropdown.Item>
      )}
    </NavDropdown>
  );
};

export default Language;
