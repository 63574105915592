import aim from '../../assets/images/icons/aim.svg';
import arrows from '../../assets/images/icons/arrows.svg';
import blik from '../../assets/images/logos/monochrome/blik.svg';
import bulp from '../../assets/images/icons/bulp.svg';
import chart from '../../assets/images/icons/chart.svg';
import checkWithDoubleCircle from '../../assets/images/icons/check-with-double-circle.svg';
import checkWithShield from '../../assets/images/icons/check-with-shield.svg';
import couple from '../../assets/images/public/couple.png';
import checkWithCircle from '../../assets/images/icons/check-with-circle.svg';
import controls from '../../assets/images/icons/controls.svg';
import crown from '../../assets/images/icons/crown.svg';
import dashboard from '../../assets/images/icons/dashboard.svg';
import dashboardScreen from '../../assets/images/public/dashboard-screen.png';
import dashboardScreenVip from '../../assets/images/public/dashboard-screen-vip.png';
import depositMobileScreen from '../../assets/images/public/deposit-mobile-screen.png';
import dots from '../../assets/images/public/dots.svg';
import ezeeWatermark from '../../assets/images/logos/ezeewallet/ezeewallet-logo-watermark.svg';
import golf from '../../assets/images/public/golf.png';
import klarna from '../../assets/images/logos/monochrome/klarna.svg';
import label from '../../assets/images/icons/label.svg';
import logo from '../../assets/images/logos/ezeewallet/ezeewallet-logo.svg';
import logoWhite from '../../assets/images/logos/ezeewallet/ezeewallet-logo-white.svg';
import recentTransactionsMobileView from '../../assets/images/public/recent-transactions-mobile-screen.png';
import mobileScreens from '../../assets/images/public/mobile-screens.png';
import money from '../../assets/images/icons/money.svg';
import neosurf from '../../assets/images/logos/monochrome/neosurf.svg';
import officeStuff from '../../assets/images/public/office_stuff.png';
import pay_u from '../../assets/images/logos/pay_u.svg';
import payid from '../../assets/images/logos/payid.svg';
import paysafecard from '../../assets/images/logos/monochrome/paysafecard.svg';
import phoneTransferView from '../../assets/images/public/personal-phone.png';
import poli from '../../assets/images/logos/monochrome/poli.svg';
import przelewy from '../../assets/images/logos/monochrome/przelewy.svg';
import phoneVip from '../../assets/images/public/phone-vip.png';
import pick from '../../assets/images/icons/pick.svg';
import simple from '../../assets/images/icons/controls.svg';
import screens from '../../assets/images/icons/screens.svg';
import success from '../../assets/images/icons/success.svg';
import lock from '../../assets/images/icons/lock.svg';
import underMaintenance from '../../assets/images/public/under-maintenance.svg';
import upi from '../../assets/images/logos/monochrome/upi.svg';
import wallet from '../../assets/images/icons/wallet.svg';

export default {
  aim,
  arrows,
  blik,
  bulp,
  chart,
  checkWithDoubleCircle,
  checkWithShield,
  couple,
  checkWithCircle,
  controls,
  crown,
  dashboard,
  dashboardScreen,
  dashboardScreenVip,
  depositMobileScreen,
  dots,
  ezeeWatermark,
  golf,
  klarna,
  label,
  logo,
  logoWhite,
  recentTransactionsMobileView,
  mobileScreens,
  money,
  neosurf,
  officeStuff,
  pay_u,
  payid,
  paysafecard,
  phoneTransferView,
  poli,
  przelewy,
  phoneVip,
  pick,
  simple,
  screens,
  success,
  lock,
  underMaintenance,
  upi,
  wallet
};
