import React, { FC } from 'react';
import { Card } from 'react-bootstrap';

import SignInForm from 'bundles/components/public/signIn/SignInForm';
import { LoginViewProps } from 'bundles/types';
import If from 'bundles/components/common/If';
import { setCardTitle } from 'bundles/components/public/signIn/utils';

const SignInView: FC<LoginViewProps> = (props: LoginViewProps) =>
  <div className="sign-in">
    <If condition={props.inline_message}>
      <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
    </If>

    <Card>
      <Card.Header as={'h1'} className="border-0">{setCardTitle()}</Card.Header>
      <Card.Body>
        <SignInForm {...props} />
      </Card.Body>
    </Card>
  </div>;

export default SignInView;
