import { FilterContextAction, FilterStateInterface } from './types';

const GET_PAGE_DATA = 'GET_PAGE_DATA';
const TOGGLE_SHOW_ADD_CRITERIA = 'TOGGLE_SHOW_ADD_CRITERIA';
const ON_CHANGE_FILTER_FIELD = 'ON_CHANGE_FILTER_FIELD';
const ON_CLOSE_BADGE = 'ON_CLOSE_BADGE';
const ON_TRIGGER_LOADER = 'ON_TRIGGER_LOADER';
const ON_TRIGGER_ACTION = 'ON_TRIGGER_ACTION';

const FilterReducer = (state: FilterStateInterface, action: FilterContextAction) => {
  switch (action.type) {
  case GET_PAGE_DATA:
    return { ...state, ...action.payload };
  case TOGGLE_SHOW_ADD_CRITERIA:
    return { ...state, showAddCriteria: action.payload.showAddCriteria };
  case ON_CHANGE_FILTER_FIELD:
    return { ...state, tableRowAnimated: '', currentLoader: action.payload.currentLoader };
  case ON_TRIGGER_ACTION:
    return { ...state, ...action.payload };
  case ON_CLOSE_BADGE:
    return { ...state, tableRowAnimated: '', currentBadge: action.payload.currentBadge };
  case ON_TRIGGER_LOADER:
    return { ...state, tableRowAnimated: '', currentLoader: action.payload.currentLoader };
  default:
    throw new Error('no such filter context event');
  }
};

export default FilterReducer;
