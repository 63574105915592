import React, { createContext, useReducer } from 'react';

import { urlUtils } from 'bundles/utils';

import FilterReducer from './FilterReducer';
import { FilterStateInterface, FilterContextInterface, FilterContextProviderInterface } from './types';

const initialState: FilterStateInterface = {
  url: `/${window.location.pathname.split('/').slice(2).join('/')}`,
  collection_name: urlUtils.getLastSegment() || 'collection',
  total_collection_name: 'total',
  page_title: '',
  report_type: '',
  children: null,
  showAddCriteria: false,
  collection: undefined,
  total: [],
  pagination: undefined,
  tableRowAnimated: 'table-row-animated',
  currentLoader: '',
  currentFilterEvent: null,
  currentBadge: undefined,
  query_params: {},
  inline_message: '',
  filter_selects_options: {},
  no_data_message: undefined
};

export const FilterContext = createContext<FilterContextInterface>({
  state: initialState,
  dispatch: () => {}
});

export const FilterContextProvider = (props: FilterContextProviderInterface) => {
  const [state, dispatch] = useReducer(FilterReducer, { ...initialState, ...props });

  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {props.children}
    </FilterContext.Provider>
  );
};
