import React, { FC,  forwardRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const PhoneNumber: FC<any> = forwardRef((props: WithFormControlProps, ref: InputRef) =>
  <PhoneInput
    country={'us'}
    inputProps={{
      name: props.name,
      className: props.className,
      id: (props.id || props.name),
      required: props.required,
      pattern: '.{7,20}',
      ref: ref || undefined
    }}
    onChange={props.onChange}
    autoFormat={false}
    {...(props.value ? { value: props.value } : {})}
  />
);

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;

