import React from 'react';

export const setDataTransfer = (refInput: React.MutableRefObject<HTMLInputElement | null>, index: number) => {
  const dt = new DataTransfer();
  const { files } = refInput.current as HTMLInputElement;

  for (let i = 0; i < (files as FileList).length; i++) {
    const file = (files as FileList)[i];

    if (index !== i) dt.items.add(file);
  }

  return dt;
};
