import React, { useEffect, useState } from 'react';
import axios from 'bundles/apis/defaults';

import If from 'bundles/components/common/If';
import { API_STATE } from 'bundles/constants';
import LoaderIcon from './LoaderIcon';
import { LoaderType } from './types';

import './styles.scss';

const Loader = ({ url, className, text = '', variant = 'primary', condition }: LoaderType) => {
  const [state, setState] = useState<{status: string; url: string | undefined}>({ status: '', url: '' });

  const compareUrl = (url: string | string[] | undefined) => {
    if(url instanceof Array) return url.filter(e => e === state.url);
    return url === state.url;
  };

  useEffect(() => {
    let isMounted = true;               // note mutable flag
    const reqInterceptor = axios.interceptors.request.use(
      function (request) {
        if (isMounted) setState(prevState => ({ ...prevState, status: API_STATE.FETCHING, url: request.url }));
        return request;
      }
    );

    const resInterceptor = axios.interceptors.response.use(function (response) {
      if (isMounted) setState(prevState => ({ ...prevState, status: API_STATE.SUCCESS }));
      return response;
    }, function (error) {
      if (isMounted) setState(prevState => ({ ...prevState, status: API_STATE.ERROR }));
      return Promise.reject(error);
    });

    return () => {
      isMounted = false;
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    }; // cleanup toggles value, if unmounted
  }, []);

  return (
    <>
      <If condition={(state.status === API_STATE.FETCHING && compareUrl(url) && !text) || condition}><span className={`loading ${className}`}><LoaderIcon variant={variant} /></span></If>
      <If condition={state.status === API_STATE.FETCHING && compareUrl(url) && text}><div className={`loading ${className}`}><span className="loading__text">{text}</span></div></If>
    </>
  );
};

export default Loader;
