import { IWallet } from 'bundles/types/walletTypes';

export const setWalletsOptionsFor = (wallets: IWallet[], target?: string) => {
  const arr: any[] = [];

  wallets?.map((e: IWallet) => arr.push({
    select_options_name: e.currency.code,
    select_options_value: target === 'batch payouts' ? e.currency.id : e.id,
    select_options_data_attrs: { 'data-alt-value': e.currency.exponent },
  })
  );

  return arr;
};
