import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import { StepsContext } from 'bundles/contexts/StepsContext';
import { ANIMATION } from 'bundles/constants';
import { NextProps } from './types';

const Next = ({ onClickNext, onClickComplete, className = '', variant = 'primary', children, ...rest } : NextProps) => {
  const { dispatch } = useContext(StepsContext);

  const next = (payload: Record<string, any> = {}) => dispatch({ type: ANIMATION.NEXT_STEP, payload: payload });
  const complete = () => dispatch({ type: ANIMATION.COMPLETE_STEPS });

  const handleOnClick = () => {
    if(onClickNext) return onClickNext(next);
    if(onClickComplete) return onClickComplete(complete);

    next();
  };

  return <Button variant={variant} className={`position-relative ${className}`.trim()} {...rest} onClick={() => handleOnClick()}>{children}</Button>;
};

export default Next;
