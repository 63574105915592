const humanizeLabel = (label: string) => {
  if(!label) return '';

  try {
    return label.replace(/_/g, ' ');
  } catch {
    return '';
  }
};

export default humanizeLabel;
