import React, { FC, ReactNode, useContext } from 'react';

import { StepsContext } from 'bundles/contexts/StepsContext';
import If from 'bundles/components/common/If';
import Next from 'bundles/components/common/Steps/Next';
import Back from 'bundles/components/common/Steps/Back';

import { ANIMATION } from 'bundles/constants';

import './styles.scss';

const Step: FC<{index: number; children: ReactNode}> & { Back: typeof Back; Next: typeof Next } = ({ index, children }: {index: number; children: ReactNode}) => {
  const { state, dispatch } = useContext(StepsContext);

  return (
    <div
      className={state.animation ? `animation ${state.animationDirection}` : ''}
      onAnimationEnd={() => dispatch({ type: ANIMATION.ANIMATION, payload: { animation: false } })}
    >
      <If condition={state.currentStep === index}>{children}</If>
    </div>
  );
};

Step.Next = Next;
Step.Back = Back;

export default Step;
