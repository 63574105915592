import { useState, useEffect } from 'react';
import axios from '@Apis/defaults';

import { API_STATE } from '@Constants/';

const useApiDetails = () => {
  const [apiDetails, setApiDetails] = useState({ status: '', url: '' });

  useEffect(() => {
    const reqInterceptor = axios.interceptors.request.use(
      function (request) {
        setApiDetails(prevState => ({ ...prevState, status: API_STATE.FETCHING, url: request.url }));
        return request;
      }
    );

    const resInterceptor = axios.interceptors.response.use(function (response) {
      setApiDetails(prevState => ({ ...prevState, status: API_STATE.SUCCESS }));
      return response;
    }, function (error) {
      setApiDetails(prevState => ({ ...prevState, status: API_STATE.ERROR }));
      return Promise.reject(error);
    });

    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return apiDetails;
};

export default useApiDetails;
