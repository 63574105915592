const getValue = (object: any, nameProcessed: string) => nameProcessed.split(' ').filter(Boolean).reduce((o, k) => (o || {})[k], object);

const processFieldName = (names: any, name: string) => {
  if(!names) return;
  const nameProcessed = name.replace(/[[,\]]/g, ' ').trim();

  return getValue(names, nameProcessed);
};

export default processFieldName;
