import DatePicker from './DatePicker';
import Input from './Input';

import MultiSelect from './MultiSelect';
import PhoneNumber from './PhoneNumber/index';
import Select from './Select';
import Textarea from './Textarea';
import FileUpload from './FileUpload';
import TinyMce from './TinyMce';
import _Checkbox from './Checkbox';

import withFormControl from 'bundles/hocs/withFormControl';
import withFormGroup from './withFormGroup';
import withInputGroup from './withInputGroup';

export const DatePickerFormGroup = withFormGroup(withFormControl(DatePicker));
export const DatePickerGroupFormGroup = withFormGroup(withInputGroup(withFormControl(DatePicker)));
export const FileUploadFormGroup = withFormGroup(withFormControl(FileUpload));
export const InputFormGroup = withFormGroup(Input);
export const InputGroupFormGroup = withFormGroup(withInputGroup(Input));
export const MultiSelectFormGroup = withFormGroup(withFormControl(MultiSelect));
export const MultiSelectGroupFormGroup = withFormGroup(withInputGroup(withFormControl(MultiSelect)));
export const PhoneFormGroup = withFormGroup(withFormControl(PhoneNumber));
export const SelectFormGroup = withFormGroup(Select);
export const SelectGroupFormGroup = withFormGroup(withInputGroup(Select));
export const CheckboxGroupFormGroup = withFormGroup(withInputGroup(_Checkbox));
export const TextareaFormGroup = withFormGroup(Textarea);
export const TinyMceFormGroup = withFormGroup(withFormControl(TinyMce));
export const Checkbox = _Checkbox;
export const CheckboxGroup = withFormGroup(withInputGroup(_Checkbox));

export { default as Radio } from './Radio';
