import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

const PasswordIcons = () => {
  const { t } = useTranslation();

  return (
    <>
      <FontAwesomeIcon icon={faQuestionCircle} role="button" className="text-primary" size="lg" />
      <div className="message alert-inline">
        <div className="message-content">
          <h6 className="bold">{t('messages.help.password_rules.title')}</h6>
          <ul>
            {[...Array(3)].map((_, i) => <li className="mt-3 ml-2" key={i}>{t(`messages.help.password_rules.message.${i}`)}</li>)}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PasswordIcons;
