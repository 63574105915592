import React, { useState, SyntheticEvent } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import Recaptcha from 'bundles/components/common/Recaptcha';
import { isValidEmail, isRequired } from 'bundles/components/common/Form/validations';
import { InputFormGroup, InputGroupFormGroup } from 'bundles/components/common/Form';
import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';
import { setBtnTitle, setFormAction } from './utils';
import { LoginViewProps } from 'bundles/types';

import './styles.scss';

const SignInForm = ({ email, recaptcha_key, recaptcha_action }: LoginViewProps) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({ disabled: false, passwordVisible: false });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const onSubmitForm = async (fv: FieldValues, e: SyntheticEvent & { target: HTMLFormElement }) => {
    setState(ps => ({ ...ps, disabled: true }));
    e.target.submit();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)} action={setFormAction()} method="post" noValidate>
      <InputFormGroup
        type="email"
        name="email"
        label={t('labels.email_address')}
        register={register}
        defaultValue={email}
        validate={{ isRequired, isValidEmail }}
        errors={errors}
      />

      <InputGroupFormGroup
        type={state.passwordVisible ? 'text' : 'password'}
        label={t('labels.password')}
        name="password"
        register={register}
        validate={{ isRequired }}
        errors={errors}
        className="border-right-0"
        appendLabel={<FontAwesomeIcon
          icon={state.passwordVisible ? faEyeSlash : faEye}
          size="lg"
          role="button"
          className="text-gray mx-3"
          onClick={() => setState(ps => ({ ...ps, passwordVisible: !ps.passwordVisible }))}
        />}
      />

      <Recaptcha sitekey={recaptcha_key} register={register} setValue={setValue} action={recaptcha_action || 'session'} />

      <input value={setBtnTitle} className="btn btn-primary btn-block" disabled={state.disabled} type="submit" />

      <If condition={ window.location.pathname !== `/${i18n.language}${URL.ADMINS}` }>
        <div className="text-center mt-4 d-block">
          <a className="forgot-password text-secondary" href={`/${i18n.language}${URL.RESET_PASSWORD_NEW}`}>{t('links.forgot_password')}</a>
        </div>
      </If>
    </Form>
  );
};

export default SignInForm;
