import * as locales from 'date-fns/locale/';

const urlParts = window.location.pathname.split('/');

const getLangFromHtmlAttr = document.getElementsByTagName('html')[0].getAttribute('lang');
const getLangFromUrl = urlParts[1];

let locale = getLangFromHtmlAttr ? getLangFromHtmlAttr : getLangFromUrl;

if(locale === 'en') locale = 'enGB';

export default locales[locale] || locales['enGB'];
