import { URL } from 'bundles/constants';
import { i18n } from 'bundles/utils';

export const setCardTitle = () => {
  switch (window.location.pathname) {
  case `/${i18n.language}${URL.ADMINS}`:
    return i18n.t('titles.administrator_login');
  case `/${i18n.language}${URL.SESSION_NEW}`:
    return i18n.t('labels.signin');
  default:
    return i18n.t('consumer.labels.resend_verification_email');
  }
};

export const setBtnTitle = window.location.pathname === `/${i18n.language}${URL.CONSUMERS_ACCOUNT_VERIFICATIONS}/new` ? i18n.t('consumer.labels.resend_email') : i18n.t('labels.signin');

export const setFormAction = () => {
  const searchQuery = window.location.pathname === `/${i18n.language}${URL.ADMINS}` ? '?user_type=admins' : window.location.search;

  return window.location.pathname === `/${i18n.language}${URL.CONSUMERS_ACCOUNT_VERIFICATIONS}/new`
    ? `/${i18n.language}${URL.CONSUMERS_ACCOUNT_VERIFICATIONS}`
    : `/${i18n.language}${URL.SESSION}${searchQuery}`.trim();
};
