export const camelize = (str: string) => str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const kebapCase = (str: string) => str && str.replace(/\s+$/g,'').replace(/\s+/g, '-').toLowerCase();

export const snakeCase  = (str: string) => str && str.replace(/\s+$/g,'').replace(/\s+/g, '_').toLowerCase();

export const stringifyJSON = (data:any) => {
  try {
    return JSON.stringify(JSON.parse(data), null, 2);
  } catch (e) {
    return data;
  }
};
