import React, { forwardRef } from 'react';

import { processFieldName } from 'bundles/utils';
import { FormFieldInterface } from './types';
import { InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const Checkbox = forwardRef(({ register, errors, className = '', validate, name = '', classNameGroup = '', label, ...rest }: FormFieldInterface, ref: InputRef) =>{  // eslint-disable-line

  return (
    <div className={`custom-control custom-checkbox ${classNameGroup}`.trim()}>
      <input
        id={rest.id || name}
        {...(typeof register === 'function' ? register(name, { validate: validate }) : register)}
        className={`custom-control-input ${processFieldName(errors as any, name) ? 'form-error' : ''}`.trim()}
        {...rest}
        type="checkbox"
        {...(ref && { ref: ref })}
      />
      <label htmlFor={name} className={`custom-control-label ${validate?.isRequired ? 'required' : ''}`.trim()}>
        {label}
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
